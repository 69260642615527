<template>
    <transition name="scale">
        <SubMenu  v-if="$store.state.subMenu" />
    </transition>
    <div class="navActions"  v-show="!$store.state.formats.current">
        <SelectorLang />
        <button @click="$store.commit('toggleSubmenu')" class="button-hamburger"></button>
        <!-- <router-link :to="`/`" class="button-hamburger"></router-link> -->
        <div class="social-links desktop">
            <p class="mbottom"><a class="link" target="_blank" :href="$store.getters.getGeneral.instagram">Instagram</a></p>
            <p class="mbottom"><a class="link" target="_blank" :href="$store.getters.getGeneral.facebook">Facebook</a></p>
        </div>
    </div>
    <transition name="fade">
        <FormateMenu  :style="getVariables()" />
    </transition>
    <AgendaSlider />
    <router-view :style="getVariables()"></router-view>
</template>


<script>
import FormateMenu from '@/components/FormateMenuComponent.vue';
import SubMenu from '@/components/SubMenuComponent.vue';
import SelectorLang from '@/components/SelectorLangComponent.vue';
import AgendaSlider from '@/components/AgendaSliderComponent.vue';

export default {
  name: 'Home',
  components: {
      FormateMenu,
      SelectorLang,
      SubMenu,
      AgendaSlider
  },
  data(){
    return {
    }
  },
  mounted(){

  },
  methods: {
      getVariables(){
          return {
              '--width-content': `calc(100% - 40px * ${this.$store.state.formats.data.length-1})`,
              '--number-items': this.$store.state.formats.data.length
          }
      }
  }
}
</script>
