import { createStore } from 'vuex'
import { getHttp } from '../http/fetchApi';

export default createStore({
    state: {
        formats: {
            current: null,
            data: []
        },
        pages: {
            current: null,
            data: []
        },
        general: {},
        subMenu: false
    },
    getters: {
        getFormats: state => {
            return state.formats.data;
        },
        getFormat: (state) => {
            var formats = state.formats.data.filter( format => (format.slug == state.formats.current) );
            return formats.length ? formats[0] : null;
        },
        getFormatPosition: (state) => {
            var i = 0;
            for (var format of state.formats.data){
                if(format.slug == state.formats.current){
                    return i;
                }
                i++;
            }
            return null;
        },
        getPages: state => {
            return state.pages.data;
        },
        getPage: (state) => {
            var pages = state.pages.data.filter( page => (page.slug == state.pages.current) );
            return pages.length ? pages[0] : null;
        },
        getPageById: (state,) => (id) => {
            var pages = state.pages.data.filter( page => (page.id == id) );
            return pages.length ? pages[0] : null;
        },
        getGeneral: state => {
            return state.general;
        },
    },
    mutations: {
        setFormats: (state, formats) => {
            state.formats.data = formats;
        },
        setCurrentFormat: (state, slug) => {
            state.formats.current = slug;
        },
        setPages: (state, pages) => {
            state.pages.data = pages;
        },
        setCurrentPage: (state, slug) => {
            state.pages.current = slug;
        },
        setGeneral: (state, general) => {
            state.general = general;
        },
        toggleSubmenu: (state) => {
            state.subMenu = (state.subMenu) ? false : true
        },
        hideSubmenu: (state) => {
            state.subMenu = false
        }
    },
    actions: {
        async fetchFormats({ commit }, language) {
            const { data } = await getHttp("/formats.json?language=" + language);
            commit('setFormats', data.data);
        },
        async fetchPages({ commit }, language) {
            const { data } = await getHttp("/pages.json?language=" + language);
            commit('setPages', data.data);
        },
        async fetchGeneral({ commit }, language) {
            const { data } = await getHttp("/general.json?language=" + language);
            commit('setGeneral', data);
        }
    },
})
