<template>
    <div class="subMenu">
        <button class="close" v-if="!$route.params.slug" @click="$store.commit('toggleSubmenu')"></button>
        <ul :class="['subMenu__menu__items', {'subMenu__menu__items-open': $store.state.pages.current}]">
            <li class="subMenu__menu__item subMenu__menu__item_mobile" @click="$store.commit('toggleSubmenu')">
                <router-link :to="`/`">
                    <h2>{{ $t("Home") }}</h2>
                </router-link>
                <ul>
                    <li :class="['subMenu__menu__item']" v-for="format in $store.getters.getFormats" v-bind:key="format">
                        <router-link :to="$url(`formats/${format.slug}`)">
                            <h2 v-if="format.title == 'Work it Out'">Work <del>it</del> Out</h2>
                            <h2 v-else>{{ format.title }}</h2>
                        </router-link>
                    </li>
                </ul>
            </li>
            <li  @click="$store.commit('hideSubmenu')" :class="['subMenu__menu__item', {'subMenu__menu__item-active': $store.state.pages.current == page.slug}]" v-for="page in $store.getters.getPages" v-bind:key="page">
                <router-link :to="$url(`page/${page.slug}`)">
                    <h2>{{ page.title }}</h2>
                </router-link>
                <div>
                  <Close class="desktop" />
                  <slot :name="page.slug"></slot>
                </div>
            </li>
        </ul>
    </div>

</template>


<script>
import Close from '@/components/CloseComponent.vue';

export default {
    name: 'SubMenu',
    components: {
      Close
    },
    props: {
        content: String
    },
    data(){
        return {

        }
    },
    mounted(){

    },
    methods: {

    }
}
</script>
