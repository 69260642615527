import { createRouter, createWebHistory } from 'vue-router'
import Root from "./Root"

import { i18n, defaultLocale } from '../i18n'
import store from '../store'

const routes = [
    {
        path: '/:locale(en|de)?',
        name: 'Home',
        component: () => import('../views/Home.vue')
    },
    {
        path: '/:locale(en|de)?/formats/:slug',
        name: 'Formats',
        component: () => import('../views/Formats.vue')
    },
    {
        path: '/:locale(en|de)?/page/:slug',
        name: 'Pages',
        component: () => import('../views/Pages.vue')
    }

]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    mode: 'history',
    scrollBehavior() {
        document.getElementById('app').scrollIntoView();
    },

})

router.beforeEach((to, from , next) => {
    if (to.params.locale === from.params.locale) {
        next()
        return
    }


    const lang = to.params.locale || defaultLocale
    i18n.global.locale = lang

    store.dispatch('fetchPages', lang);
    store.dispatch('fetchFormats', lang);
    store.dispatch('fetchGeneral', lang);

    next();
});

export default router
