<template>
  <div class="selector-lang">
    <a href="/en" v-if="$i18n.locale == 'de'">En</a>
    <a href="/" v-else>De</a>
  </div>
</template>

<script>
import { defaultLocale } from '../i18n'

export default {
    name: 'SelectorLang',
    components: {

    },
    data(){
        return {

        }
    },
    mounted(){
    },
    methods: {

    }
}
</script>
