import { createApp } from 'vue'

import App from './App.vue'


import router from './router'
import store from './store'
// eslint-disable-next-line
import styles from './assets/sass/app.scss';
import VueClickAway from "vue3-click-away";

import { i18n, defaultLocale } from './i18n/index.js'


window.$ = window.jQuery = require('jquery');

export const app = createApp(App);

app.config.globalProperties.FORMATERS_ID = [5, 89, 73, 65, 97, 81];
app.config.globalProperties.$setHeaderData = function(data) {
    document.title = ` ${data.title} - workitout-platform.com`;
}

app.config.globalProperties.$push = function(link) {
    if(defaultLocale == i18n.global.locale){
        router.push(`/${link}`)
    }else{
        router.push(`/${i18n.global.locale}/${link}`)
    }
}

app.config.globalProperties.$url = function(link) {
    if(defaultLocale == i18n.global.locale){
        return`/${link}`
    }else{
        return `/${i18n.global.locale}/${link}`
    }
}


app
    .use(i18n)
    .use(store)
    .use(router)
    .use(VueClickAway)
    .mount('#app')
