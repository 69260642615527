import baseHttp from './baseHttp';

export const getHttp = async (
    url,
    params,
    options,
) => baseHttp(url,
    'get',
    {
        ...options,
        params
    });
