<template>
    <div class="formatMenu">
        <img class="mainLogo" v-show="!$store.state.formats.current" src="/assets/images/PNG_Startseite_72.png" alt="Work it Out" />
        <ul :class="['menu__items', `menu__items-total-${$store.getters.getFormats.length}`, {'menu__items-open': $store.state.formats.current}]">
            <li :style="getMainColor(format.color)" :class="{'menu__item': true, 'menu__item-active': $store.state.formats.current == format.slug}" v-for="(format, index) in $store.getters.getFormats" v-bind:key="format">
                <router-link :to="$url(`formats/${format.slug}`)" :title="format.title">
                    <div class="menu__item-number"  v-show="!$store.state.formats.current">
                        {{ index+1 }}
                    </div>
                    <h2 v-if="format.title == 'Work it Out'">Work <del>it</del> Out</h2>
                    <h2 v-else>{{ format.title }}</h2>
                </router-link>
            </li>
        </ul>
    </div>
</template>


<script>

export default {
    name: 'FormateMenu',
    components: {

    },
    data(){
        return {

        }
    },
    updated(){

    },
    methods: {
        getMainColor(color){
            return {
                '--main-color': color,
            }
        }
    },

}
</script>
