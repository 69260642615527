<template>
  <div class="agendaSlider">
    <template v-for="(agenda, index) in getEvents()" v-bind:key="agenda">
      <div class="agendaSlider__slide" :class="{ 'active': (index==  0) }" :style="{ '--main-color': agenda.format.color }">
        <a :href="$url(`formats/${agenda.format.slug}`)">
          <div class="agendaSlider__slide__date">
            <span class="twoDates" v-if="agenda.date.to">
              {{ agenda.date.from }} - {{ agenda.date.to }}
            </span>
            <span v-else>
              {{ agenda.date.from }}
            </span>
          </div>
          <div class="agendaSlider__slide__title">{{ agenda.title }}</div>
          <div class="agendaSlider__slide__category">{{ agenda.category.join(', ') }}</div>
          <div class="agendaSlider__slide__text" v-html="clipText(agenda.contentText)"></div>
        </a>
      </div>
    </template>
  </div>
</template>


<script>
import clip from "text-clipper"

export default {
  name: 'AgendaSlider',
  components: {

  },
  data(){
    return {
      pos: 0,
      timer: null,
    }
  },
  mounted(){
    var _this = this;
    this.timer = setInterval(function(){
      _this.pos++;
      window.$(".agendaSlider .agendaSlider__slide").removeClass("active");
      window.$(".agendaSlider .agendaSlider__slide").eq(_this.pos).addClass("active");
      if(window.$(".agendaSlider .agendaSlider__slide").length == _this.pos+1){
        _this.pos = -1;
      }
    }, 5000);
  },
  unmounted(){
    clearInterval(this.timer);
  },
  methods: {
    clipText(text){
      return clip(text, 120, { html: true, maxLines: 2 });
    },
    getEvents(){
      var events = [];
      for (let format of this.$store.getters.getFormats){
        for (let agenda of format.agenda){
          var _agenda = agenda;
          _agenda['format'] = format;
          events.push(_agenda);
        }
      }
      return events.sort(this.sortByDate);
    },
    sortByDate(a, b){
      // Use toUpperCase() to ignore character casing
      const bandA = a.date.from.split('.').reverse().join();
      const bandB = b.date.from.split('.').reverse().join();

      let comparison = 0;
      if (bandA > bandB) {
        comparison = 1;
      } else if (bandA < bandB) {
        comparison = -1;
      }
      return comparison;
    }
  }
}
</script>
