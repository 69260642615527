<template>
    <router-link :to="$url(``)" class="close"></router-link>
</template>


<script>
// @ is an alias to /src

export default {
    name: 'Close',
    components: {

    },
    data(){
        return {

        }
    },
    mounted(){

    },
}
</script>
